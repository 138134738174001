import parse from "html-react-parser";

const Content = () => {

    //current date
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    var currentDate = new Date()
    var day = currentDate.getDate()
    let month = months[currentDate.getMonth()];
    var year = currentDate.getFullYear()


    const Postion = "Co-op Student,IT Software Developer";
    const companyName = "WorkSafeBC";

    //Company name
    const companyInfo = () => {

    }


    // const openingParaString = ` I am writing to express my interest in the opening for <strong>${Postion}</strong> position at <strong>${companyName}</strong>.
    //     I graduated with a Computer Science Engineering degree in 2019, and currently study in Computer Studies and Information Systems Postgraduate Diploma with a major in Data Analytics at Douglas College.
    //      I’ve been in the IT industry for around 2.7 years and worked as a full-stack software developer. During that period, I worked integrating payment gateways and designing and developing web applications. 
    //      Throughout my experience, I've developed an understanding of data analytics and programming.<br />
    //     `


    const closingparaString = `
            At <strong>${companyName}</strong>, I’m excited to contribute with my technical skills and to join your Analytics Data Service Team. I see potential growth for me as I’m sure I’ll have so many opportunities to learn and implement my skills.<br />
            One of my favorite quotes: <q><i>We're entering a new world in which data may be more important than software.</i></q> I believe that data science is the future.<br/>
            Please review my resume (www.akshaysaini.co.in) for a more in-depth illustration of my work history and accomplishments. I would appreciate the opportunity to interview at your earliest convenience.<br />
        `


    // const openingParaString = `

    // I am writing to express my interest in the <strong>${Postion}</strong> position at <strong>${companyName}</strong>. As a highly skilled and motivated developer with over 2+ years of experience in developing, testing, and deploying .Net applications, I am confident that I would be a valuable asset to your team.<br/>
    // <br/>
    // My expertise lies in developing web applications using .Net technologies such as <strong>C#, ASP.Net, ADO.NET and .Net Core</strong>. I have a solid understanding of <strong>object-oriented programming and solid principles</strong> and a proven track record of delivering projects on time and within budget. I have also worked with various front-end technologies like HTML, CSS, and JavaScript, and have experience integrating applications with databases like MS SQL Server.
    // <br/><br/>
    // In my previous role, I was responsible for designing and implementing scalable and maintainable applications, as well as providing ongoing support and maintenance. I have also worked closely with project managers, stakeholders, and end-users to understand their requirements and to ensure that their needs are met.
    // <br/><br/>
    // I am excited about the opportunity to join <strong>${companyName}</strong> and bring my skills and experience to the table. I am eager to work with a team of like-minded individuals and contribute to the success of the organization.
    // <br/><br/>
    // Thank you for considering my application. I look forward to the opportunity to discuss my qualifications further and to learn more about this exciting opportunity.
    // <br/><br/>
    // Sincerely,
    // `
    const openingParaString = `

    I am writing to express my interest in the <strong>${Postion}</strong> position at <strong>${companyName}</strong>.
    I graduated with a degree in Computer Science Engineering in 2019.
    Currently, I am pursuing a Post-Baccalaureate Diploma in Computer and Information Systems at Douglas College.
    
    <br/><br/>I have been a full-stack software developer in the IT industry for around 2.7 years.
    During this period, I have gained hands-on experience in integrating payment gateways, designing, and developing web applications using various technologies. 
    My core expertise lies in developing web applications using .Net technologies such as C#, ASP.Net, ADO.NET, and .Net Core. 
    <br/><br/>Additionally I possess the following qualifications that enable me to fulfill your requirements successfully:

    <ul>
    <li>Experience in C#, .Net, Javascript, and other programming languages and analytical tools.</li>
    <li>knowledge of PowerBI, Tableau, R and SAS.</li>
    <li>Strong knowledge of SQL database design principles.</li>
    <li>Solid understanding of OOPS and SOLID concepts.</li>
    <li>Always eager to learn and stay up-to-date with the latest technologies</li>
    </ul>

    At <strong>${companyName}</strong>, I am excited to have the opportunity to contribute my technical skills and join development Team.
    I see immense potential for growth in this role as I will have ample opportunities to learn and apply my skills. I am eager to collaborate with the team and contribute to the success of the organization.
    
    <br/><br/>One of my favorite quote by Filipe Fortes - <q>Debugging is like being the detective in a crime movie where you are also the murderer.</q>
    <br/><br/>
    
    Please review my resume (<a href="www.akshaysaini.co.in">www.akshaysaini.co.in</a>) for a more in-depth illustration of my work history and accomplishments. I would appreciate the opportunity to interview at your earliest convenience.

    <br><br>
    Thank you for considering my application. I look forward to the opportunity to discuss my qualifications further and to learn more about this exciting opportunity.
    <br/><br/>
    Sincerely,
    `

    return (
        <>
            <div className="cv">
                <h2>Cover Letter</h2>
                <hr />
                <div>
                    <span>
                        {month + " " + day + ", " + year}<br/><br/>
                    </span>

                    <span>
                        {/* To: Sandi Lauridsen-Hoegh<br /> */}
                        <strong>{companyName}</strong><br />
                        Vancover, BC<br/><br/>
                        Dear Hiring Manager,<br />
                    </span>

                    <div className="mt-3">
                        {parse(openingParaString)}
                    </div>
                    {/* I believe I have the following qualifications to successfully fulfill your requirements:<br />
                    <ul>
                        <li>Experience in C#, .Net, Java, SAS, and other languages</li>
                        <li>Knowledge of SQL Database Design</li>
                        <li>OOPS and SOLID Concepts</li>
                        <li>Always eager to learn</li>
                    </ul>
                    <div className="mt-2">
                        {parse(closingparaString)}
                    </div> 
                    It would be a pleasure to share more of my experience and personality with you. Thank you for your consideration.<br />
                    <br />
                    Sincerely,<br /> */}
                    Akshay<br />
                </div>

            </div>
        </>);
}

export default Content;