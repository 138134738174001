const Skillsv1 = ({ skills }) => {
  return (
    <>
      <div className="leftHighBorder">
        <span>Skills</span>
      </div>
      <div className="skills">
        <div>
          <span>Programming Languages:</span>
          <ul>
            <li>
              <span>Proficient in:</span>
              {" " + skills?.LanguageSkils.Proficient.join(", ")}
            </li>
            <li>
              <span>Familiar with:</span>
              {" " + skills?.LanguageSkils.FamiliarWith.join(", ")}
            </li>
            <li>
              <span>Scripting Languages:</span>
              {" " + skills?.LanguageSkils.ScriptingLang.join(", ")}
            </li>
          </ul>
        </div>
        <div>
          <span>Softwares:</span>
          <ul>
            <li>
              <span>Database:</span>
              {" " + skills?.Softwares.Database.join(", ")}
            </li>
            <li>
              <span>Version Control:</span>
              {" " + skills?.Softwares.VersionControl.join(", ")}
            </li>
            <li>
              <span>Project Management Tools:</span>
              {" " + skills?.Softwares.ProjectManagementTools.join(", ")}
            </li>
            <li>
              <span>Development Tools:</span>
              {" " + skills?.Softwares.DevelopmenTools.join(", ")}
            </li>
            <li>
              <span>Other Libraries/Tools:</span>
              {" " + skills?.Softwares.OtherTools.join(", ")}
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Skillsv1;
