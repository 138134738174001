const Repater = ({ items, id, staticText }) => {
  let count = 0;
  let str = "";
  let direction = true;

  setInterval(() => {
    let target = document.getElementById({ id }.id);
    str = (direction ? items.slice(0, count++) : items.slice(0, count--)) + "|";
    target.innerHTML = str;
    if (count >= items.length || count === 0) {
      direction = !direction;
    }
  }, 200);

  return (
    <h1 className="repeater mx-2">
      {staticText}
      <span className="repeaterChild" id={id}></span>
    </h1>
  );
};

export default Repater;
