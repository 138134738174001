import ContactV1 from "./leftNavItems/contactV1";
import Skillsv1 from "./leftNavItems/skillsV1";
import Summaryv1 from "./leftNavItems/summaryv1";
import { FaDownload } from "react-icons/fa";
const LeftNavV1 = ({ leftNavData, isCoverLetter = false }) => {
  return (
    <div className="leftNav">
      <div className="Heading">

        {!isCoverLetter &&
          <><img
            className="my-pic"
            src={process.env.PUBLIC_URL + "/mypic.jpg"}
            alt="Akshay" /><br /></>
        }
        <label style={{ fontSize: "64px", fontFamily: "ralewayr" }}>
          AKSHAY
        </label>
        <br />
        {!isCoverLetter && <><label>SAINI</label><br /></>}
        <span style={{ fontSize: "24px" }}>Software Developer</span>
        <div
          style={{
            borderBottom: "2.5px solid white",
            marginLeft: "46%",
            width: "50px",
          }}
        ></div>
      </div>
      <div className="navContent">
        <Summaryv1 summary={leftNavData?.Summary}></Summaryv1>
        <Skillsv1 skills={leftNavData?.Skills}></Skillsv1>
        <ContactV1 Contact={leftNavData?.Contact}></ContactV1>
      </div>

      {!isCoverLetter && <><div className="leftHighBorder">
        <span>Side Projects</span>
      </div><div className="aDec">
          <span>
            <a href="https://alumnihub.akshaysaini.co.in/">AlumniHub</a>
            <br />
          </span>
      <span>
            <a href="https://worksmart.akshaysaini.co.in">WorkSmart</a>
            <br />
          </span>
        </div></>}

      {!isCoverLetter && <>
      <div className="leftHighBorder">
        <span>Interests</span>
      </div>
        <div>
          <span>
            {leftNavData?.Intrests.map((i, x) => {
              return (
                <div key={x}>
                  {i} <br />
                </div>
              );
            })}
            <br />
          </span>
        </div>

        <div className="downloadResume  mt-4">
          <a
            className="mainBtn btn btn-lg btn-outline-primary"
            href="/AkshayResume.pdf"
            download
          >
            <FaDownload size={28} color={"white"}></FaDownload> Download Resume
          </a>
        </div><div className="footerV1">
          <span>
            Created by <strong>Akshay</strong> With ❤️
          </span>
        </div></>}
    </div>
  );
};

export default LeftNavV1;
