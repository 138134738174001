import {
  FaWhatsapp,
  FaLinkedin,
  FaPhone,
  FaGoogle,
  FaGithub,
} from "react-icons/fa";

const ContactV1 = ({ Contact }) => {
  const t = () => {
    var ElementsA = document.querySelectorAll("#social-Icons li");
  };

  return (
    <>
      <div className="leftHighBorder">
        <span>Contact Me</span>
      </div>
      <div className="boldHead aDec">
        <span>PhoneNumber:</span>
        <a href={"tel:" + Contact?.ContactMe}>{Contact?.ContactMe}</a> <br />
        <span>Email:</span>{" "}
        <a href={"mailto:" + Contact?.Email}>{Contact?.Email}</a> <br />
        <span>Website:</span> <a href={Contact?.Website}>{Contact?.Website}</a>
        <br />
        <span>Digital Badges:</span> <a href={"https://www.credly.com/users/akshay97/badges"}>{"Credly"}</a>
        <br />
        <span>Profile:</span> <a href={"https://www.hackerrank.com/profile/ghosthimesh97"}>{"HackerRank"}</a>
        <br />
        {<div id="social-Icons" className="wrapper mt-3">
          <ul>
            <li className="">
              <a href={"tel:" + Contact?.ContactMe}>
                <FaPhone size={28}></FaPhone>
              </a>
            </li>
            <li className="whatsapp">
              <a
                href={"https://wa.me/" + Contact?.ContactMe.replace("-", "")}
                target="_blank"
              >
                <FaWhatsapp size={28}></FaWhatsapp>
              </a>
            </li>
            <li className="twitter">
              <a href="https://www.linkedin.com/in/ghosths97" target="_blank">
                <FaLinkedin size={28}></FaLinkedin>
              </a>
            </li>
            <li className="google">
              <a href={"mailto:" + Contact?.Email}>
                <FaGoogle size={28}></FaGoogle>
              </a>
            </li>
            <li className="github">
              <a href={Contact?.Github} target="_blank">
                <FaGithub size={28}></FaGithub>
              </a>
            </li>
          </ul>
        </div> }
      </div>
    </>
  );
};

export default ContactV1;
