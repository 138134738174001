const Summaryv1 = ({ summary }) => {
  return (
    <>
    <div className="leftHighBorder">
      <span>Summary</span>
    </div>
      <p>{summary}</p>
    </>
  );
};

export default Summaryv1;
