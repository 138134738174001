import { useState, useEffect } from "react";
import LeftNavV1 from "./leftNavv1";
import axios from "axios";
import MainPageV1 from "./mainPageV1";

const HeaderV1 = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    axios.get("../data.json").then((i) => setData(i.data));
  }, []);

  return (
    <div className="main-container">
      <LeftNavV1 leftNavData={data?.LeftNav}></LeftNavV1>
      <MainPageV1
        workExp={data?.WorkExp}
        Projects={data?.Projects}
        Edu={data?.Education}
      ></MainPageV1>
    </div>
  );
};

export default HeaderV1;
