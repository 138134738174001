import { NavLink } from "react-router-dom";
const HeaderMenu = () => {
  const MenuItem = ({ itemName }) => {
    return (
      <div className="menu-items item">
        <NavLink to={"/" + itemName}>{itemName}</NavLink>
      </div>
    );
  };

  const menuItems = [
    "Home",
    "About",
    "Skills",
    "Badges",
    "Projects",
    "Team",
    "Contact",
  ];

  return (
    <div className="menu">
      {menuItems.map((x, i) => {
        return <MenuItem key={i} itemName={x}></MenuItem>;
      })}
    </div>
  );
};

export default HeaderMenu;
