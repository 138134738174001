import { useState, useEffect } from "react";
import LeftNavV1 from "../v1/leftNavv1";
import axios from "axios";
import Content from "./content";


const CoverLetter = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    axios.get("../data.json").then((i) => setData(i.data));
  }, []);

  return (
    <div className="main-container">
      <LeftNavV1 leftNavData={data?.LeftNav} isCoverLetter={true}></LeftNavV1>
      <Content></Content>
    </div>
  );
};

export default CoverLetter;
