import "./App.css";
import "./index.css";
import Header from "./component/v2/Header/header";
import HeaderV1 from "./component/v1/headerv1";
import { Route, Routes } from "react-router-dom";
import Reviews from "./component/reviews/review";
import { useState } from "react";
import CoverLetter from "./component/cv/coverLetter";

function App() {
  const [isV1, setisV1] = useState(true);

  return (
    <div className="App">
      <Routes>
        <Route path="/review" element={<Reviews></Reviews>} />
        <Route path="/coverLetter" element={<CoverLetter></CoverLetter>} />
        <Route path="*" element={isV1 ? <HeaderV1 /> : <Header />} />
      </Routes>
    </div>
  );
}

export default App;
