import Repater from "../../../helper/repeater";
import RepeaterArray from "../../../helper/repeaterArray";
import HeaderMenu from "./headerMenu";

const Header = () => {
  return (
    <div className="Header">
      <Repater
        id="headerRepeater"
        items={"kshay Saini"}
        staticText="A"
      ></Repater>

      <HeaderMenu></HeaderMenu>
    </div>
  );
};

export default Header;
