import Header from "../v2/Header/header";

const Reviews = () => {
  return (
    <>
      <Header />
    </>
  );
};

export default Reviews;
