import parse from "html-react-parser";
import { useState } from "react";
import React from "react";

class MainPageV1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isAdded: false };
  }

  WorkSummary(wrkExp, x) {
    return (
      <div key={x} className="ps-4">
        <div style={{ display: "flex" }}>
          <div>
            {wrkExp.CompanyLogo ? (
              <img
                style={{ marginRight: "10px", width: "70px" }}
                src={process.env.PUBLIC_URL + wrkExp.CompanyLogo}
                alt="Akshay"
              />
            ) : (
              ""
            )}
          </div>
          <div>
            <h5>{wrkExp.Position}</h5>
            <em className="aDec blackTxt">
              <a href={wrkExp.CompanyURL} target="_blank">
                {parse(wrkExp.Company + "<br/>" + wrkExp.Duration)}
              </a>
            </em>
          </div>
        </div>
        <div className=" pt-2">
          <ul>
            {wrkExp.Responsibilities.map((i, x) => {
              return (
                <li key={x}>
                  <div>{parse(i)}</div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  }

  renderProjects(Project, x) {
    return (
      <div key={x} className="ps-4 project">
        <h5 className="aDec blackTxt">
          <a href={Project.projectLink} target="_blank">
            {parse(Project.projectName)}
          </a>
        </h5>
        <em className="aDec blackTxt">
          <a href={Project.ProjectCompanyURL} target="_blank">
            {parse(Project.ProjectFor)}
          </a>
        </em>
        <br />
        <div className="ps-5 pt-2">
          <div>
            <div>{parse(Project.ProjectDes)}</div>
            <div className="mt-2">
              <strong>
                {Project.ProjectDes ? "" : "Major"}Responsibilities:
              </strong>
            </div>
          </div>

          <ul className="ps-5">
            {Project.Responsibilities.map((i, x) => {
              return (
                <li key={x}>
                  <div className="fontl">{parse(i)}</div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  }

  renderEducation(Edu, x) {
    return (
      <div key={x} className="ps-4 mt-4">
        <div style={{ display: "flex" }}>
          <div>
            {Edu.collegeLogo ? (
              <img
                style={{ marginRight: "10px", width: "70px" }}
                src={process.env.PUBLIC_URL + Edu.collegeLogo}
                alt="Akshay"
              />
            ) : (
              ""
            )}
          </div>

          <div style={{width: "-webkit-fill-available"}}>
            <h5>{Edu?.degree}</h5>
            <div className="row">
              <div className="col-sm-9">
                <em>{Edu.collegeName + Edu.location}</em>
              </div>
              <div className="col-sm-3">
                <em>{Edu.year}</em>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  render() {
    return (
      <>
        <div className="rightNav">
          <h3>WORK EXPERIENCE</h3>
          <hr />
          {this.props.workExp?.map((i, x) => {
            return this.WorkSummary(i, x);
          })}

          <div className="mt-5">
            <h3>EDUCATION</h3>
            <hr />
            <div className="Education">
              {this.props.Edu?.map((i, x) => {
                return this.renderEducation(i, x);
              })}
            </div>
          </div>

          <div className="mt-5">
            <h3>PROJECTS</h3>
            <hr />
            <div className="projects">
              {this.props.Projects?.map((i, x) => {
                return this.renderProjects(i, x);
              })}
            </div>
          </div>

          <div id="badgesSection" className="mt-5">
            <h3>BADGES</h3>
            <hr />
            <div className="badges row ps-4">
              <div
                data-iframe-width="150"
                data-iframe-height="270"
                data-share-badge-id="59831d74-aa35-495b-8f38-d74d2dabf21b"
                data-share-badge-host="https://www.credly.com"
              ></div>
              <div>
                <div
                  data-iframe-width="150"
                  data-iframe-height="270"
                  data-share-badge-id="726fa374-d50d-41ac-82ca-59ecded7bdc0"
                  data-share-badge-host="https://www.credly.com"
                ></div>
              </div>
              <div>
                <div
                  data-iframe-width="150"
                  data-iframe-height="270"
                  data-share-badge-id="73510385-a024-46c5-99eb-4cb098bd210a"
                  data-share-badge-host="https://www.credly.com"
                ></div>
              </div>
              <div>
                <div
                  data-iframe-width="150"
                  data-iframe-height="270"
                  data-share-badge-id="ec95cf85-f877-469b-ac3e-9eb41524b940"
                  data-share-badge-host="https://www.credly.com"
                ></div>
              </div>
              <div>
                <div
                  data-iframe-width="150"
                  data-iframe-height="270"
                  data-share-badge-id="e5b036e1-ef3a-4dcf-9285-f6a622eeb677"
                  data-share-badge-host="https://www.credly.com"
                ></div>
              </div>
            </div>
          </div>

          <div className="mt-4">
            <h3>CERTIFICATIONS</h3>
            <hr />
            <div className="row ps-4">
              <div className="passed-certificates-list">
                <a
                  className="certificate-link passed-certificate passed-certificate--active"
                  data-event-category="HRC Skills"
                  data-event-label="CertificateIcon"
                  data-analytics="CertificateIcon"
                  data-attr1="python_basic"
                  data-cd-skill-name="python_basic"
                  href=" https://www.hackerrank.com/certificates/d90cd4ae15a5"
                  target="_blank"
                >
                  <div className="certificate certificate-v2">
                    <div
                      className="folded-corner"
                      style={{
                        backgroundColor: "rgb(255, 255, 255)",
                        boxShadow:
                          "rgb(255, 255, 255) 3px -3px 0px 3px rgba(0, 0, 0, 0.25) -4px 4px 6px",
                      }}
                    ></div>
                    <div className="certificate-container">
                      <h2 className="certificate-heading">
                        <span className="sr-only"> </span>Python (Basic)
                      </h2>
                      <span
                        className="certificate-heading certificate-heading-green"
                        aria-hidden="true"
                      >
                        <strong>Verified</strong>
                      </span>
                    </div>
                  </div>
                </a>
                <a
                  className="certificate-link passed-certificate"
                  data-event-category="HRC Skills"
                  data-event-label="CertificateIcon"
                  data-analytics="CertificateIcon"
                  data-attr1="java_basic"
                  data-cd-skill-name="java_basic"
                  href=" https://www.hackerrank.com/certificates/372c824f2bfb"
                  target="_blank"
                >
                  <div className="certificate certificate-v2">
                    <div
                      className="folded-corner"
                      style={{
                        backgroundColor: "rgb(255, 255, 255)",
                        boxShadow:
                          "rgb(255, 255, 255) 3px -3px 0px 3px rgba(0, 0, 0, 0.25) -4px 4px 6px",
                      }}
                    ></div>
                    <div className="certificate-container">
                      <h2 className="certificate-heading">
                        <span className="sr-only"> </span>Java (Basic)
                      </h2>
                      <span
                        className="certificate-heading certificate-heading-green"
                        aria-hidden="true"
                      >
                        <strong>Verified</strong>
                      </span>
                    </div>
                  </div>
                </a>
                <a
                  className="certificate-link passed-certificate"
                  data-event-category="HRC Skills"
                  data-event-label="CertificateIcon"
                  data-analytics="CertificateIcon"
                  data-attr1="javascript_basic"
                  data-cd-skill-name="javascript_basic"
                  href=" https://www.hackerrank.com/certificates/9cdadec38397"
                  target="_blank"
                >
                  <div className="certificate certificate-v2">
                    <div
                      className="folded-corner"
                      style={{
                        backgroundColor: "rgb(255, 255, 255)",
                        boxShadow:
                          "rgb(255, 255, 255) 3px -3px 0px 3px rgba(0, 0, 0, 0.25) -4px 4px 6px",
                      }}
                    ></div>
                    <div className="certificate-container">
                      <h2 className="certificate-heading">
                        <span className="sr-only"> </span>JavaScript (Basic)
                      </h2>
                      <span
                        className="certificate-heading certificate-heading-green"
                        aria-hidden="true"
                      >
                        <strong>Verified</strong>
                      </span>
                    </div>
                  </div>
                </a>
                <a
                  className="certificate-link passed-certificate"
                  data-event-category="HRC Skills"
                  data-event-label="CertificateIcon"
                  data-analytics="CertificateIcon"
                  data-attr1="c_sharp_basic"
                  data-cd-skill-name="c_sharp_basic"
                  href=" https://www.hackerrank.com/certificates/ec71d48dffaa"
                  target="_blank"
                >
                  <div className="certificate certificate-v2">
                    <div
                      className="folded-corner"
                      style={{
                        backgroundColor: "rgb(255, 255, 255)",
                        boxShadow:
                          "rgb(255, 255, 255) 3px -3px 0px 3px rgba(0, 0, 0, 0.25) -4px 4px 6px",
                      }}
                    ></div>
                    <div className="certificate-container">
                      <h2 className="certificate-heading">
                        <span className="sr-only"> </span>C# (Basic)
                      </h2>
                      <span
                        className="certificate-heading certificate-heading-green"
                        aria-hidden="true"
                      >
                        <strong>Verified</strong>
                      </span>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  componentDidMount() {
    if (!this.state.isAdded) {
      const script = document.createElement("script");
      script.src = "./embed.js";
      script.async = true;
      //script.onload = () => this.scriptLoaded();

      document.body.appendChild(script);
      this.state.isAdded = !this.state.isAdded;
    }
  }
}

// const MainPageV1 = ({ this.props.workExp, Projects, Edu }) => {
//   useEffect(() => {
//     axios.get("./embed.js").then((i) => console.log(i.data));
//   }, []);

//   const loadBadges = () => {};
// };

export default MainPageV1;
